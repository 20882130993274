<template>
  <div class="form-group row">
    <div class="col-md-4" v-for="(staff) in staffList" :key="staff.id">
      <b-checkbox name="check-button"
                  inline
                  :checked="isStaffExist(staff)"
                  @change="staffChanged(staff)">
        {{ staff.name }}
      </b-checkbox>
    </div>
  </div>
</template>
<script>
  export default {
    name: "RelatedStaff",
    props: {
      staffList: {
        required: false,
        type: Array
      },
      model: {
        type: Object
      }
    },
    methods: {
      isStaffExist(staff) {
        return this.model.customer_RelatedStaffs.some(s => s.staffID === staff.id);
      },
      staffChanged(staff) {
        const staffIndex = this.model.customer_RelatedStaffs.findIndex(s => s.staffID === staff.id);
        if (staffIndex >= 0) {
          this.model.customer_RelatedStaffs.splice(staffIndex, 1);
        } else {
          this.model.customer_RelatedStaffs.push({ staffID: staff.id, customerID: "" });
        }
      }
    }
  };
</script>
